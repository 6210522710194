import React from "react";
import {
  Container,
  Box,
  Grid,
  Link,
  useTheme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GoogleStore from "../../../asset/FooterItem/Google Play.svg";
import AppStore from "../../../asset/FooterItem/App Store.svg";

const useStyles = makeStyles((theme) => ({
  downloadIcon: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "3%",
    justifyContent: "center",
  },
  downIcon: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 56,
  },

  alginText: {
    textAlign: "center",
  },
  headText: {
    fontFamily: "Inter",
    fontWeight: "bolder",
    fontSize: 16,
    // fontStyle: "normal",
    textAlign: "left",
    padding: "0.5em",
    // [theme.breakpoints.down("sm")]: {
    //   textAlign: "center",
    // },
  },
  subText: {
    fontFamily: "Open Sans",
    fontSize: 16,
    textAlign: "right",
    // [theme.breakpoints.down("sm")]: {
    //   textAlign: "right",
    // },
  },
  paddingDown: {
    paddingBottom: 40,
    fontFamily: "Open Sans",
  },
  socialMediaContainer: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down(450)]: {
      padding: 0,
    },
  },
  socialIcons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    textAlign: "left",
    margin: "auto 2.5rem",
    [theme.breakpoints.down(1280)]: { margin: "auto 10rem" },
    [theme.breakpoints.down(960)]: { margin: "auto 7.5rem" },
    [theme.breakpoints.down(800)]: { margin: "auto 5rem" },
    [theme.breakpoints.down(650)]: { margin: "auto 3rem" },
    [theme.breakpoints.down(450)]: { margin: "auto 2.5rem" },
  },
}));

const Footer = () => {
  const themed = useTheme();
  const matches = useMediaQuery(themed.breakpoints.down("910"));

  const classes = useStyles();
  return (
    <Box
      px={{ xs: 3, sm: 10 }}
      py={{ xs: 3, sm: 10 }}
      bgcolor="#E2E2E2"
      className="footerContainer"
      style={{ overflow: "clip", background: "#156376" }}
    >
      {matches ? (
        <>
          <Grid container className={classes.socialMediaContainer}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                marginTop: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <div
                style={{
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <Typography
                    style={{
                      fontWeight: "Bold",
                      color: "#fff",
                      padding: "6% 0% 4%",
                    }}
                  >
                    CM App Links
                  </Typography>
                </div>
                <div>
                  <Link
                    target="_blank"
                    href="https://apps.apple.com/in/app/cogitometaverse/id1644162232"
                  >
                    <img
                      src={AppStore}
                      style={{
                        width: "125px",
                        marginRight: "0.8rem",
                      }}
                    />
                  </Link>
                  <Link
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.cogito"
                  >
                    <img
                      src={GoogleStore}
                      style={{
                        width: "125px",
                        // paddingLeft: "2%",
                      }}
                    />
                  </Link>
                </div>
              </div>
              <div
                style={
                  {
                    // display: "flex",
                    // flexDirection: "row",
                    // justifyContent: "space-around",
                  }
                }
              >
                <div>
                  <Typography
                    style={{
                      fontWeight: "Bold",
                      color: "#fff",
                      padding: "6% 0% 4%",
                    }}
                  >
                    Business App Links
                  </Typography>
                </div>
                <div>
                  <Link
                    target="_blank"
                    href="https://apps.apple.com/in/app/cogitopos/id1611183581"
                  >
                    <img
                      src={AppStore}
                      style={{
                        width: "125px",
                        marginRight: "0.8rem",
                      }}
                    />
                  </Link>
                  <Link
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.cogitoapp"
                  >
                    <img
                      src={GoogleStore}
                      style={{
                        width: "125px",
                        // paddingLeft: "2%",
                      }}
                    />
                  </Link>
                </div>
              </div>
            </Box>
            <Box className={classes.socialIcons}>
              <Link
                target="_blank"
                href="https://www.facebook.com/cogitometaverse/"
              >
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Facebook_Icon.svg`}
                  style={{
                    width: "45px",
                    // paddingLeft: "1.5%",
                  }}
                />
              </Link>
              <Link target="_blank" href="https://www.instagram.com/cogito/">
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Instagram_Icon.svg`}
                  style={{
                    width: "45px",
                    // paddingLeft: "1.5%",
                  }}
                />
              </Link>
              <Link target="_blank" href="https://twitter.com/CogitoMetaverse">
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Twitter_Icon.svg`}
                  style={{
                    width: "45px",
                    // paddingLeft: "1.5%",
                  }}
                />
              </Link>
              <Link
                target="_blank"
                href="https://www.linkedin.com/company/principality-cogito/"
              >
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/LinkedIn_Icon.svg`}
                  style={{
                    width: "45px",
                    // paddingLeft: "1.5%",
                  }}
                />
              </Link>
            </Box>
          </Grid>
          <Grid
            container
            style={{
              padding: "2rem ",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                paddingBottom: "3rem",
              }}
            >
              <Box>
                <Box className={classes.headText}>
                  <Link href="/" color="inherit" style={{ color: "#FFF" }}>
                    Home
                  </Link>
                </Box>
                <Box className={classes.headText}>
                  <Link href="/about" color="inherit" style={{ color: "#FFF" }}>
                    About
                  </Link>
                </Box>
                <Box className={classes.headText}>
                  <Link
                    href="/cogito-metaverse"
                    color="inherit"
                    style={{ color: "#FFF" }}
                  >
                    Cogito Metaverse
                  </Link>
                </Box>
                <Box className={classes.headText}>
                  <Link
                    href="/e-residency"
                    color="inherit"
                    style={{ color: "#FFF" }}
                  >
                    E-Residency
                  </Link>
                </Box>
              </Box>
              <Box>
                <Box className={classes.headText}>
                  <Link
                    href="/education"
                    color="inherit"
                    style={{ color: "#FFF" }}
                  >
                    Education
                  </Link>
                </Box>
                <Box className={classes.headText}>
                  <Link
                    href="/government"
                    color="inherit"
                    style={{ color: "#FFF" }}
                  >
                    Government
                  </Link>
                </Box>
                <Box className={classes.headText} style={{ color: "#FFF" }}>
                  <Link href="/contact" color="inherit">
                    Contact
                  </Link>
                </Box>
              </Box>
            </Box>

            <Box
              style={
                {
                  // display: 'flex',
                  // justifySelf: 'auto',
                }
              }
            >
              <Box
                style={{
                  textAlign: "center",
                }}
                // py={{ sm: 0.5 }}
              >
                <Link href="/">
                  <img
                    //src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/logo.png`}
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Cogito_Logo_white.png`}
                    style={{
                      // height: "5%",
                      width: 94,
                    }}
                  />
                </Link>
              </Box>

              <Box
                style={{
                  textAlign: "center",
                }}
                // py={{ sm: 0.5 }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "#FFF",
                  }}
                >
                  {"Copyright © "}{" "}
                  <Link
                    style={{
                      // color: "#359DB6",
                      color: "#FFF",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      textDecoration: "none",
                    }}
                    href={process.env.REACT_APP_URL_POC}
                  >
                    Principality of Cogito
                  </Link>{" "}
                  {new Date().getFullYear()}
                  {"."}
                </Typography>
              </Box>
              <Box
                style={{
                  textAlign: "center",
                }}
              >
                <Link
                  // href="/faq/#privacyTerms"
                  style={{
                    color: "#FFF",
                    textAlign: "right",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </Link>
              </Box>
              <Box
                style={{
                  textAlign: "center",
                }}
                py={{ sm: 0.5 }}
              >
                <Link
                  // href="/faq/#privacyTerms"
                  style={{
                    color: "#FFF",
                    textAlign: "right",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    textDecoration: "none",
                  }}
                >
                  Terms & condition
                </Link>
              </Box>
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            style={{
              padding: "0rem 6rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                textAlign: "left",
              }}
            >
              <Link
                target="_blank"
                href="https://www.facebook.com/cogitometaverse/"
              >
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Facebook_Icon.svg`}
                  style={{
                    width: "45px",
                    paddingRight: "0.7rem",
                  }}
                />
              </Link>
              <Link target="_blank" href="https://www.instagram.com/cogito/">
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Instagram_Icon.svg`}
                  style={{
                    width: "45px",
                    paddingRight: "0.7rem",
                  }}
                />
              </Link>
              <Link target="_blank" href="https://twitter.com/CogitoMetaverse">
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Twitter_Icon.svg`}
                  style={{
                    width: "45px",
                    paddingRight: "0.7rem",
                  }}
                />
              </Link>
              <Link
                target="_blank"
                href="https://www.linkedin.com/company/principality-cogito/"
              >
                <img
                  src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/LinkedIn_Icon.svg`}
                  style={{
                    width: "45px",
                    paddingRight: "0.7rem",
                  }}
                />
              </Link>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                // alignItems: "end",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                }}
              >
                <div>
                  <Typography
                    style={{
                      fontWeight: "Bold",
                      color: "#fff",
                      paddingBottom: "4%",
                    }}
                  >
                    CM App Links{" "}
                  </Typography>
                </div>
                <div>
                  <Link
                    target="_blank"
                    href="https://apps.apple.com/in/app/cogitometaverse/id1644162232"
                  >
                    <img
                      src={AppStore}
                      style={{
                        width: "150px",
                        // height: "87%",
                        // paddingRight:"0.5rem"
                      }}
                      alt="appstore"
                    />
                  </Link>
                  <Link
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.cogito"
                  >
                    <img
                      src={GoogleStore}
                      style={{
                        width: "150px",
                        // height:"87%",
                        paddingLeft: "0.8rem",
                      }}
                      alt="playstore"
                    />
                  </Link>
                </div>
              </div>
              <div
                style={
                  {
                    // display: "flex",
                    // flexDirection: "row",
                    // justifyContent: "space-around",
                  }
                }
              >
                <div>
                  <Typography
                    style={{
                      fontWeight: "Bold",
                      color: "#fff",
                      padding: "6% 0% 4%",
                    }}
                  >
                    Business App Links
                  </Typography>
                </div>
                <div>
                  <Link
                    target="_blank"
                    href="https://apps.apple.com/in/app/cogitopos/id1611183581"
                  >
                    <img
                      src={AppStore}
                      style={{
                        width: "150px",
                        // height: "87%",
                        // paddingRight:"0.5rem"
                      }}
                      alt="appstore"
                    />
                  </Link>
                  <Link
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.cogitoapp"
                  >
                    <img
                      src={GoogleStore}
                      style={{
                        width: "150px",
                        // height:"87%",
                        paddingLeft: "0.8rem",
                      }}
                      alt="playstore"
                    />
                  </Link>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid
            container
            style={{
              padding: "1rem 6rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box className={classes.headText} py={{ sm: 1 }}>
                <Link href="/" color="inherit" style={{ color: "#FFF" }}>
                  Home
                </Link>
              </Box>
              <Box className={classes.headText} py={{ sm: 1 }}>
                <Link href="/about" color="inherit" style={{ color: "#FFF" }}>
                  About
                </Link>
              </Box>
              <Box className={classes.headText} py={{ sm: 1 }}>
                <Link
                  href="/cogito-metaverse"
                  color="inherit"
                  style={{ color: "#FFF" }}
                >
                  Cogito Metaverse
                </Link>
              </Box>
              <Box className={classes.headText} py={{ sm: 1 }}>
                <Link
                  href="/e-residency"
                  color="inherit"
                  style={{ color: "#FFF" }}
                >
                  E-Residency
                </Link>
              </Box>
              <Box className={classes.headText} py={{ sm: 1 }}>
                <Link
                  href="/education"
                  color="inherit"
                  style={{ color: "#FFF" }}
                >
                  Education
                </Link>
              </Box>
              <Box className={classes.headText} py={{ sm: 1 }}>
                <Link
                  href="/government"
                  color="inherit"
                  style={{ color: "#FFF" }}
                >
                  Government
                </Link>
              </Box>
              <Box className={classes.headText} py={{ sm: 1 }}>
                <Link href="/contact" color="inherit" style={{ color: "#FFF" }}>
                  Contact
                </Link>
              </Box>
            </Box>

            <Box>
              <Box className={classes.subText} py={{ sm: 1.8 }}>
                <Link href="/">
                  <img
                    // src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/footerimages/logo.png`}
                    src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Cogito_Logo_white.png`}
                    style={{
                      // height: "5%",
                      width: 105,
                    }}
                  />
                </Link>
              </Box>

              <Box className={classes.subText} py={{ sm: 0.5 }}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "400",
                    color: "#FFFFFF",
                  }}
                >
                  {"Copyright © "}{" "}
                  <Link
                    style={{
                      // color: "#359DB6",
                      color: "#FFF",
                      fontFamily: "Inter",
                      fontWeight: "400",
                      textDecoration: "none",
                    }}
                    href={process.env.REACT_APP_URL_POC}
                  >
                    Principality of Cogito
                  </Link>{" "}
                  {new Date().getFullYear()}
                  {"."}
                </Typography>
              </Box>
              <Box className={classes.subText} py={{ sm: 0.5 }}>
                <Link
                  // href="/faq/#privacyTerms"
                  style={{
                    color: "#FFFFFF",
                    textAlign: "right",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    textDecoration: "none",
                  }}
                >
                  Privacy Policy
                </Link>
              </Box>
              <Box className={classes.subText} py={{ sm: 0.5 }}>
                <Link
                  // href="/faq/#privacyTerms"
                  style={{
                    color: "#FFFFFF",
                    textAlign: "right",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    textDecoration: "none",
                  }}
                >
                  Terms & condition
                </Link>
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Footer;
